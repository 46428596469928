import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const preset = {
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#344955',
                secondary: '#F9AA33',
                secondaryLight: '#E5CAA9',
                long: '#FFB140',
                short: '#CBFF5C',
                positive: '#33CA7F',
                negative: '#FF5A5F',
                background: '#f5f5f7',
                appBar: '#d3d3d7',
                navigationDrawer: '#344955',
                navigationDrawer2: '#FFFFFF',
                sheet: '#FFFFFF'
            },
        },
    },
}

export default new Vuetify({preset})
