<template>
    <v-app :style="{background: $vuetify.theme.themes.light.background, color: $vuetify.theme.themes.light.primary}">
        <the-app-bar></the-app-bar>

        <the-navigation-drawer></the-navigation-drawer>

        <v-main>
            <router-view></router-view>

<!--            <v-navigation-drawer-->
<!--                absolute-->
<!--                right-->
<!--                :value="palette"-->
<!--            >-->
<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            primary-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.primary"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            secondary-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.secondary"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            long-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.long"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            short-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.short"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            positive-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.positive"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            negative-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.negative"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            background-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.background"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            app bar-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.appBar"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            navigation drawer background-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.navigationDrawer"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            navigation drawer text-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.navigationDrawer2"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->

<!--                <v-list-item>-->
<!--                    <v-list-item-content>-->
<!--                        <v-list-item-title>-->
<!--                            sheet-->
<!--                        </v-list-item-title>-->
<!--                        <v-list-item-subtitle>-->
<!--                            <v-color-picker-->
<!--                                hide-mode-switch-->
<!--                                mode="hexa"-->
<!--                                v-model="$vuetify.theme.themes.light.sheet"-->
<!--                            >-->
<!--                            </v-color-picker>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item-content>-->
<!--                </v-list-item>-->
<!--            </v-navigation-drawer>-->
        </v-main>

        <the-snackbar></the-snackbar>

        <the-footer></the-footer>

        <the-bottom-navigation></the-bottom-navigation>
    </v-app>
</template>

<script>
import TheAppBar from '@/components/TheAppBar'
import TheBottomNavigation from '@/components/TheBottomNavigation'
import TheFooter from '@/components/TheFooter'
import TheNavigationDrawer from '@/components/TheNavigationDrawer'
import TheSnackbar from '@/components/TheSnackbar'

export default {
    components: {TheAppBar, TheBottomNavigation, TheFooter, TheNavigationDrawer, TheSnackbar},
    created: function () {
        if (this.$store.getters.token && !this.$store.getters.user) {
            this.$store.dispatch('userinfo')
        }
    },
    computed: {
        palette: function () {
            return this.$store.state.palette
        }
    },
    data() {
        return {}
    },
    methods: {},
    beforeUpdate() {
        // if (this.$router.currentRoute.path === '/') {
        //     this.$vuetify.theme.dark = false
        // } else {
        //     this.$vuetify.theme.dark = true
        // }
    }
}
</script>
