import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const API_URL = process.env.VUE_APP_BACKEND_API_URL + '/auth/'

export default new Vuex.Store({
    state: {
        drawer: false,
        palette: false,
        position: null,
        status: null,
        snackbar: {text: null, visible: false},
        token: localStorage.getItem('token'),
        user: null
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, token) {
            state.status = 'success'
            state.token = token
        },
        auth_error(state) {
            state.status = 'error'
        },
        token(state, token) {
            state.token = token
        },
        userinfo(state, user) {
            state.user = user
        },
        logout(state) {
            state.status = null
            state.token = null
            state.user = null
        },
        drawer(state) {
            state.drawer = !state.drawer
        },
        palette(state) {
            state.palette = !state.palette
        },
        position(state, position) {
            state.position = position
        },
        openSnackbar(state, message) {
            state.snackbar.text = message
            state.snackbar.visible = true
        },
        closeSnackbar(state) {
            state.snackbar.text = null
            state.snackbar.visible = false
        }
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')

                delete axios.defaults.headers.common['Authorization']

                const data = new URLSearchParams()
                data.append('grant_type', 'password')
                data.append('username', user.username)
                data.append('password', user.password)

                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }

                axios
                    .post(API_URL + 'login', data, config)
                    .then(response => {
                        const token = response.data.access_token
                        commit('token', token)
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

                        axios
                            .get(API_URL + 'userinfo')
                            .then(response => {
                                const user = response.data
                                commit('userinfo', user)
                            })

                        resolve(response)
                    })
                    .catch(error => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(error)
                    })
            })
        },
        userinfo({commit}) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.token

                axios
                    .get(API_URL + 'userinfo')
                    .then(response => {
                        const user = response.data
                        commit('userinfo', user)
                    })
                    .catch(error => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(error)
                    })
            })
        },
        register({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: API_URL + 'register', data: user, method: 'POST'})
                    .then(response => {
                        const token = response.data.token
                        const user = response.data.user
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = token
                        commit('auth_success', token, user)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('auth_error', error)
                        localStorage.removeItem('token')
                        reject(error)
                    })
            })
        },
        logout({commit}) {
            axios
                .post(API_URL + 'logout')
                .then(() => {
                    commit('logout')
                })

            localStorage.removeItem('token')
            delete axios.defaults.headers.common['Authorization']
        },
        drawer({commit}) {
            commit('drawer')
        },
        position({commit}, position) {
            commit('position', position)
        },
        snackbar({commit}, message) {
            if (message) {
                commit('openSnackbar', message)
            } else {
                commit('closeSnackbar')
            }
        }
    },
    getters: {
        drawer: state => state.drawer,
        position: state => state.position,
        snackbar: state => state.snackbar,
        token: state => state.token,
        user: state => state.user
    }
})
